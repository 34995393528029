import React, { ReactNode, useCallback, useMemo } from "react";
import type { BaseStyle } from "widgets/BaseWidget";
import { WidgetType, WIDGET_PADDING } from "constants/WidgetConstants";
import { generateClassName } from "utils/generators";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { snipingModeSelector } from "selectors/editorSelectors";
import { previewModeSelector } from "selectors/editorSelectors";
import WidgetFactory from "utils/WidgetFactory";
import { getSelectedWidget, getSelectedWidgets } from "selectors/ui";
import { memoize } from "lodash";
import type { AppState } from "@appsmith/reducers";
import { POSITIONED_WIDGET } from "constants/componentClassNameConstants";
import ErrorBoundary from "components/editorComponents/ErrorBoundry";

export type PositionedContainerProps = {
  style?: BaseStyle;
  align?: string;
  width?: number | string;
  children: ReactNode;
  parentId?: string;
  widgetId: string;
  widgetType: WidgetType;
  selected?: boolean;
  focused?: boolean;
  resizeDisabled?: boolean;
};

export const checkIsDropTarget = memoize(function isDropTarget(
  type: WidgetType,
) {
  return !!WidgetFactory.widgetConfigMap.get(type)?.isCanvas;
});

export function PositionedFixedContainer(props: PositionedContainerProps) {
  const isSnipingMode = useSelector(snipingModeSelector);
  const selectedWidgets = useSelector(
    (state: AppState) => state.ui.widgetDragResize.selectedWidgets,
  );
  const focusedWidget = useSelector(
    (state: AppState) => state.ui.widgetDragResize.focusedWidget,
  );

  const isResizing = useSelector(
    (state: AppState) => state.ui.widgetDragResize.isResizing,
  );
  const isDragging = useSelector(
    (state: AppState) => state.ui.widgetDragResize.isDragging,
  );
  // memoized classname
  const containerClassName = useMemo(() => {
    return (
      generateClassName(props.widgetId) +
      ` ${POSITIONED_WIDGET} t-backdrop-widget-${props.widgetType
        .split("_")
        .join("")
        .toLowerCase()}`
    );
  }, [props.widgetType, props.widgetId]);

  // TODO: Experimental fix for sniping mode. This should be handled with a single event
  const stopEventPropagation = (e: any) => {
    !isSnipingMode && e.stopPropagation();
  };
  const isOthersEdit =
    (isDragging || isResizing) && focusedWidget !== props.widgetId;
  const isCurrentEdit = selectedWidgets.includes(props.widgetId);
  const currentStyle: any = {
    position: "absolute",
    padding: "20px 0px",
    width: props.width,
    top: 20,
    zIndex: isCurrentEdit ? 3 : isOthersEdit ? -2 : 1,
    opacity: isOthersEdit ? 0.5 : 1,
    margin: "0 auto",
  };
  if (props.align) {
    if (props.align === "center") {
      currentStyle.left = 0;
      currentStyle.right = 0;
    }
    if (props.align === "left") {
      currentStyle.left = 0;
    }
    if (props.align === "right") {
      currentStyle.right = 0;
    }
  }
  return (
    <div
      className={containerClassName}
      id={props.widgetId}
      style={currentStyle}
      onClick={stopEventPropagation}
    >
      <ErrorBoundary center>{props.children}</ErrorBoundary>
    </div>
  );
}

PositionedFixedContainer.padding = WIDGET_PADDING;

export default PositionedFixedContainer;
