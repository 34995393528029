import type { ExtraDef } from "utils/autocomplete/dataTreeTypeDefCreator";
import { generateTypeDef } from "utils/autocomplete/dataTreeTypeDefCreator";
import type { AppsmithEntity } from "entities/DataTree/dataTreeFactory";
import _ from "lodash";
import { EVALUATION_PATH } from "utils/DynamicBindingUtils";
import type { JSCollectionData } from "reducers/entityReducers/jsActionsReducer";
import type { Def } from "tern";
import type { ActionEntity } from "entities/DataTree/types";

const isVisible = {
  "!type": "bool",
  "!doc": "Boolean value indicating if the widget is in visible state",
};

export const entityDefinitions = {
  APPSMITH: (entity: AppsmithEntity, extraDefsToDefine: ExtraDef) => {
    const generatedTypeDef = generateTypeDef(
      _.omit(entity, "ENTITY_TYPE", EVALUATION_PATH),
      extraDefsToDefine,
    );
    if (
      typeof generatedTypeDef === "object" &&
      typeof generatedTypeDef.geolocation === "object"
    ) {
      return {
        ...generatedTypeDef,
        geolocation: {
          ...generatedTypeDef.geolocation,
          "!doc":
            "The user's geo location information. Only available when requested",
          "!url":
            "https://docs.appsmith.com/v/v1.2.1/framework-reference/geolocation",
          getCurrentPosition:
            "fn(onSuccess: fn() -> void, onError: fn() -> void, options: object) -> void",
          watchPosition: "fn(options: object) -> void",
          clearWatch: "fn() -> void",
        },
        echartInstance: {
          callFunc:
            "fn(widgetName: string, funcName: string, options?: any) -> +Promise[:t=[!0.<i>.:t]]",
        },
      };
    }
    return generatedTypeDef;
  },
  ACTION: (entity: ActionEntity, extraDefsToDefine: ExtraDef) => {
    const dataDef = generateTypeDef(entity.data, extraDefsToDefine);

    let data: Def = {
      "!doc": "The response of the action",
    };

    if (_.isString(dataDef)) {
      data["!type"] = dataDef;
    } else {
      data = { ...data, ...dataDef };
    }
    return {
      "!doc":
        "Actions allow you to connect your widgets to your backend data in a secure manner.",
      "!url": "https://docs.appsmith.com/v/v1.2.1/framework-reference/run",
      isLoading: "bool",
      data,
      responseMeta: {
        "!doc": "The response meta of the action",
        "!type": "?",
      },
      run: "fn(params: ?) -> +Promise[:t=[!0.<i>.:t]]",
      clear: "fn() -> +Promise[:t=[!0.<i>.:t]]",
    };
  },
  ECHART_WIDGET: {
    "!doc":
      "EChart widget is used to view the graphical representation of your data. Echart is the go-to widget for your data visualisation needs.",
    "!url": "https://docs.appsmith.com/widget-reference/chart",
    isVisible: true,
    chartData: {
      seriesName: "string",
      // data: "[$__chartDataPoint__$]",
      data: "any",
    },
    xAxisName: "string",
    yAxisName: "string",
    selectedDataItem: "any",
  },
  FORMILY_WIDGET: {
    "!doc": "复杂表单解决方案",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    isVisible: isVisible,
    formData: "any",
  },
  TARO_PICKER_WIDGET: {
    "!doc": "Taro 选择器",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    isVisible: isVisible,
  },
  TARO_SWIPER_WIDGET: {
    "!doc": "轮播",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_GRID_WIDGET: {
    "!doc": "网格内容",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    currentItem: "any",
  },
  TARO_TEXT_WIDGET: {
    "!doc": "文本",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    text: "string",
  },
  TARO_LIST_WIDGET: {
    "!doc": "列表",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    currentItem: "any",
    data: "[any]",
  },
  TARO_POPUP_WIDGET: {
    "!doc": "底部弹窗",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_IMAGE_WIDGET: {
    "!doc": "图片",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_BUTTON_WIDGET: {
    "!doc": "按钮",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_CELL_WIDGET: {
    "!doc": "单元格",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_HTML_WIDGET: {
    "!doc": "富文本",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    content: "string",
  },
  TARO_SKU_WIDGET: {
    "!doc": "商品规格",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    submitData: "any",
  },
  TARO_KV_WIDGET: {
    "!doc": "键值对",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_TABS_WIDGET: {
    "!doc": "标签页",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    selectedTab: "any",
  },
  TARO_BOTTOM_BAR_WIDGET: {
    "!doc": "底部面板",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_ACTION_BAR_WIDGET: {
    "!doc": "动作栏",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_SEARCH_WIDGET: {
    "!doc": "搜索框",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    text: "string",
  },
  TARO_LOADING_WIDGET: {
    "!doc": "加载遮罩",
    "!url": "https://docs.appsmith.com/widget-reference/input",
  },
  TARO_CHECKBOX_WIDGET: {
    "!doc": "复选框",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    isVisible,
    isChecked: "bool",
    isDisabled: "bool",
  },
  TARO_FORM_WIDGET: {
    "!doc": "表单",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    formData: "any",
  },
  MAPBACKDROP_WIDGET: {
    "!doc":
      "EChart widget is used to view the graphical representation of your data. Chart is the go-to widget for your data visualisation needs.",
    "!url": "https://docs.appsmith.com/widget-reference/chart",
    isVisible: true,
    chartData: {
      seriesName: "string",
      // data: "[$__chartDataPoint__$]",
      data: "any",
      custom_name: "string",
    },
    xAxisName: "string",
    yAxisName: "string",
    selectedDataItem: "any",
  },

  MULTI_SELECT_TREE_WIDGET: {
    "!doc":
      "Multi TreeSelect is used to capture user inputs from a specified list of permitted inputs/Nested Inputs. A TreeSelect can capture a single choice as well as multiple choices",
    "!url": "https://docs.appsmith.com/widget-reference/treeselect",
    isVisible: isVisible,
    selectedOptionValues: {
      "!type": "[string]",
      "!doc": "The array of values selected in a treeselect dropdown",
      "!url": "https://docs.appsmith.com/widget-reference/treeselect",
    },
    selectedOptionLabels: {
      "!type": "[string]",
      "!doc": "The array of selected option labels in a treeselect dropdown",
      "!url": "https://docs.appsmith.com/widget-reference/treeselect",
    },
    isDisabled: "bool",
    isValid: "bool",
    options: "[$__dropdownOption__$]",
  },
  ICON_BUTTON_WIDGET: {
    "!doc":
      "Icon button widget is just an icon, along with all other button properties.",
    "!url": "https://docs.appsmith.com/widget-reference/icon-button",
    isVisible: isVisible,
  },
  CHECKBOX_GROUP_WIDGET: {
    "!doc":
      "Checkbox group widget allows users to easily configure multiple checkboxes together.",
    "!url": "https://docs.appsmith.com/widget-reference/checkbox-group",
    isVisible: isVisible,
    isDisabled: "bool",
    isValid: "bool",
    options: "[$__dropdownOption__$]",
    selectedValues: "[string]",
  },
  STATBOX_WIDGET: {
    "!doc": "Show and highlight stats from your data sources",
    "!url": "https://docs.appsmith.com/widget-reference/stat-box",
    isVisible: isVisible,
  },
  AUDIO_RECORDER_WIDGET: {
    "!doc":
      "Audio recorder widget allows users to record using their microphone, listen to the playback, and export the data to a data source.",
    "!url": "https://docs.appsmith.com/widget-reference/recorder",
    isVisible: isVisible,
    blobURL: "string",
    dataURL: "string",
    rawBinary: "string",
  },
  PROGRESSBAR_WIDGET: {
    "!doc": "Progress bar is a simple UI widget used to show progress",
    "!url": "https://docs.appsmith.com/widget-reference/progressbar",
    isVisible: isVisible,
    progress: "number",
  },
  SWITCH_GROUP_WIDGET: {
    "!doc":
      "Switch group widget allows users to create many switch components which can easily by used in a form",
    "!url": "https://docs.appsmith.com/widget-reference/switch-group",
    selectedValues: "[string]",
  },
  CAMERA_WIDGET: {
    "!doc":
      "Camera widget allows users to take a picture or record videos through their system camera using browser permissions.",
    "!url": "https://docs.appsmith.com/widget-reference/camera",
    imageBlobURL: "string",
    imageDataURL: "string",
    imageRawBinary: "string",
    videoBlobURL: "string",
    videoDataURL: "string",
    videoRawBinary: "string",
  },
  MAP_CHART_WIDGET: {
    "!doc":
      "Map Chart widget shows the graphical representation of your data on the map.",
    "!url": "https://docs.appsmith.com/widget-reference/map-chart",
    isVisible: isVisible,
    selectedDataPoint: {
      id: "string",
      label: "string",
      originalId: "string",
      shortLabel: "string",
      value: "number",
    },
  },
  INPUT_WIDGET_V2: {
    "!doc":
      "An input text field is used to capture a users textual input such as their names, numbers, emails etc. Inputs are used in forms and can have custom validations.",
    "!url": "https://docs.appsmith.com/widget-reference/input",
    text: {
      "!type": "string",
      "!doc": "The text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/input",
    },
    inputText: {
      "!type": "string",
      "!doc": "The unformatted text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/input",
    },
    isValid: "bool",
    isVisible: isVisible,
    isDisabled: "bool",
  },
  CURRENCY_INPUT_WIDGET: {
    "!doc":
      "An input text field is used to capture a currency value. Inputs are used in forms and can have custom validations.",
    "!url": "https://docs.appsmith.com/widget-reference/currency-input",
    text: {
      "!type": "string",
      "!doc": "The formatted text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/currency-input",
    },
    value: {
      "!type": "number",
      "!doc": "The value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/currency-input",
    },
    isValid: "bool",
    isVisible: isVisible,
    isDisabled: "bool",
    countryCode: {
      "!type": "string",
      "!doc": "Selected country code for Currency",
    },
    currencyCode: {
      "!type": "string",
      "!doc": "Selected Currency code",
    },
  },
  PHONE_INPUT_WIDGET: {
    "!doc":
      "An input text field is used to capture a phone number. Inputs are used in forms and can have custom validations.",
    "!url": "https://docs.appsmith.com/widget-reference/phone-input",
    text: {
      "!type": "string",
      "!doc": "The text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/phone-input",
    },
    value: {
      "!type": "string",
      "!doc": "The unformatted text value of the input",
      "!url": "https://docs.appsmith.com/widget-reference/phone-input",
    },
    isValid: "bool",
    isVisible: isVisible,
    isDisabled: "bool",
    countryCode: {
      "!type": "string",
      "!doc": "Selected country code for Phone Number",
    },
    dialCode: {
      "!type": "string",
      "!doc": "Selected dialing code for Phone Number",
    },
  },
  CIRCULAR_PROGRESS_WIDGET: {
    "!doc": "Circular Progress is a simple UI widget used to show progress",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    progress: "number",
  },
  JSON_FORM_WIDGET: (widget: any) => ({
    "!doc":
      "JSON Form widget can be used to auto-generate forms by providing a JSON source data.",
    // TODO: Update the url
    "!url": "https://docs.appsmith.com/widget-reference",
    formData: generateTypeDef(widget.formData),
    sourceData: generateTypeDef(widget.sourceData),
    fieldState: generateTypeDef(widget.fieldState),
    isValid: "bool",
  }),
  PROGRESS_WIDGET: {
    "!doc":
      "Progress indicators commonly known as spinners, express an unspecified wait time or display the length of a process.",
    "!url": "https://docs.appsmith.com/widget-reference/progress",
    isVisible: isVisible,
    progress: "number",
  },
  DOCUMENT_VIEWER_WIDGET: {
    "!doc": "Document viewer widget is used to show documents on a page",
    "!url": "https://docs.appsmith.com/reference/widgets/document-viewer",
    isVisible: isVisible,
    docUrl: "string",
  },
  NUMBER_SLIDER_WIDGET: {
    "!doc":
      "Number slider widget is used to capture user feedback from a range of values",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    value: "number",
  },
  CATEGORY_SLIDER_WIDGET: {
    "!doc":
      "Category slider widget is used to capture user feedback from a range of categories",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    value: "string",
  },
  RANGE_SLIDER_WIDGET: {
    "!doc":
      "Range slider widget is used to capture user feedback from a range of values",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    start: "number",
    end: "number",
  },
  CODE_SCANNER_WIDGET: {
    "!doc": "Scan a Code",
    "!url": "https://docs.appsmith.com/reference/widgets/code-scanner",
    isVisible: isVisible,
    isDisabled: "bool",
    value: "string",
  },
  ANIMATION_WIDGET: {
    "!doc":
      "Category slider widget is used to capture user feedback from a range of categories",
    "!url": "https://docs.appsmith.com/widget-reference/circular-progress",
    isVisible: isVisible,
    value: "string",
  },
};

/*
  $__name__$ is just to reduce occurrences of global def showing up in auto completion for user as `$` is less commonly used as entityName/

  GLOBAL_DEFS are maintained to support definition for array of objects which currently aren't supported by our generateTypeDef.
*/
export const GLOBAL_DEFS = {
  $__dropdownOption__$: {
    label: "string",
    value: "string",
  },
  $__dropdrowOptionWithChildren__$: {
    label: "string",
    value: "string",
    children: "[$__dropdrowOptionWithChildren__$]",
  },
  $__chartDataPoint__$: {
    x: "string",
    y: "string",
  },
  $__file__$: {
    data: "string",
    dataFormat: "string",
    name: "text",
    type: "file",
  },
  $__mapMarker__$: {
    lat: "number",
    long: "number",
    title: "string",
    description: "string",
  },
};

export const GLOBAL_FUNCTIONS = {
  "!name": "DATA_TREE.APPSMITH.FUNCTIONS",
  navigateTo: {
    "!doc": "Action to navigate the user to another page or url",
    "!type":
      "fn(pageNameOrUrl: string, params: {}, target?: string) -> +Promise[:t=[!0.<i>.:t]]",
  },
  showAlert: {
    "!doc": "Show a temporary notification style message to the user",
    "!type": "fn(message: string, style: string) -> +Promise[:t=[!0.<i>.:t]]",
  },
  showModal: {
    "!doc": "Open a modal",
    "!type": "fn(modalName: string) -> +Promise[:t=[!0.<i>.:t]]",
  },
  logout: {
    "!doc": "logout the user",
    "!type": "fn() -> +Promise[:t=[!0.<i>.:t]]",
  },
  closeModal: {
    "!doc": "Close a modal",
    "!type": "fn(modalName: string) -> +Promise[:t=[!0.<i>.:t]]",
  },
  storeValue: {
    "!doc": "Store key value data locally",
    "!type": "fn(key: string, value: any) -> +Promise[:t=[!0.<i>.:t]]",
  },
  removeValue: {
    "!doc": "Remove key value data locally",
    "!type": "fn(key: string) -> +Promise[:t=[!0.<i>.:t]]",
  },
  clearStore: {
    "!doc": "Clear all key value data locally",
    "!type": "fn() -> +Promise[:t=[!0.<i>.:t]]",
  },
  download: {
    "!doc": "Download anything as a file",
    "!type":
      "fn(data: any, fileName: string, fileType?: string) -> +Promise[:t=[!0.<i>.:t]]",
  },
  copyToClipboard: {
    "!doc": "Copy text to clipboard",
    "!type": "fn(data: string, options: object) -> +Promise[:t=[!0.<i>.:t]]",
  },
  resetWidget: {
    "!doc": "Reset widget values",
    "!type":
      "fn(widgetName: string, resetChildren: boolean) -> +Promise[:t=[!0.<i>.:t]]",
  },
  setInterval: {
    "!doc": "Execute triggers at a given interval",
    "!type": "fn(callback: fn, interval: number, id?: string) -> void",
  },
  clearInterval: {
    "!doc": "Stop executing a setInterval with id",
    "!type": "fn(id: string) -> void",
  },
  postWindowMessage: {
    "!doc":
      "Establish cross-origin communication between Window objects/page and iframes",
    "!type": "fn(message: unknown, source: string, targetOrigin: string)",
  },
};

export const getPropsForJSActionEntity = ({
  config,
  data,
}: JSCollectionData): Record<string, string> => {
  const properties: Record<string, any> = {};
  const actions = config.actions;
  if (actions && actions.length > 0)
    for (let i = 0; i < config.actions.length; i++) {
      const action = config.actions[i];
      properties[action.name + "()"] = "Function";
      if (data && action.id in data) {
        properties[action.name + ".data"] = data[action.id];
      }
    }
  const variablesProps = config.variables;
  if (variablesProps && variablesProps.length > 0) {
    for (let i = 0; i < variablesProps.length; i++) {
      const variableProp = variablesProps[i];
      properties[variableProp.name] = variableProp.value;
    }
  }
  return properties;
};

export type EntityDefinitionsOptions = keyof typeof entityDefinitions;
