export enum EVAL_WORKER_SYNC_ACTION {
  SETUP = "SETUP",
  EVAL_TREE = "EVAL_TREE",
  EVAL_ACTION_BINDINGS = "EVAL_ACTION_BINDINGS",
  CLEAR_CACHE = "CLEAR_CACHE",
  VALIDATE_PROPERTY = "VALIDATE_PROPERTY",
  UNDO = "undo",
  REDO = "redo",
  UPDATE_REPLAY_OBJECT = "UPDATE_REPLAY_OBJECT",
  SET_EVALUATION_VERSION = "SET_EVALUATION_VERSION",
  INIT_FORM_EVAL = "INIT_FORM_EVAL",
  UNINSTALL_LIBRARY = "UNINSTALL_LIBRARY",
  LINT_TREE = "LINT_TREE",
  UPDATE_ACTION_DATA = "UPDATE_ACTION_DATA",
}

export enum EVAL_WORKER_ASYNC_ACTION {
  EVAL_TRIGGER = "EVAL_TRIGGER",
  EVAL_EXPRESSION = "EVAL_EXPRESSION",
  LOAD_LIBRARIES = "LOAD_LIBRARIES",
  INSTALL_LIBRARY = "INSTALL_LIBRARY",
}

export const EVAL_WORKER_ACTIONS = {
  ...EVAL_WORKER_SYNC_ACTION,
  ...EVAL_WORKER_ASYNC_ACTION,
};

export enum MAIN_THREAD_ACTION {
  PROCESS_TRIGGER = "PROCESS_TRIGGER",
  PROCESS_BATCHED_TRIGGERS = "PROCESS_BATCHED_TRIGGERS",
  PROCESS_STORE_UPDATES = "PROCESS_STORE_UPDATES",
  PROCESS_LOGS = "PROCESS_LOGS",
  LINT_TREE = "LINT_TREE",
  PROCESS_JS_FUNCTION_EXECUTION = "PROCESS_JS_FUNCTION_EXECUTION",
  PROCESS_JS_VAR_MUTATION_EVENTS = "PROCESS_JS_VAR_MUTATION_EVENTS",
  UPDATE_DATATREE = "UPDATE_DATATREE",
  SET_META_PROP_FROM_SETTER = "SET_META_PROP_FROM_SETTER",
}
