export * from "ce/pages/AdminSettings/config/general";
import { config as adminConfig } from "ce/pages/AdminSettings/config/general";
import type {
  AdminConfigType,
  Setting,
} from "@appsmith/pages/AdminSettings/config/types";
import {
  SettingCategories,
  SettingTypes,
} from "@appsmith/pages/AdminSettings/config/types";

export const APPSMITH_PROGRAMMATIC_ACCESS_CONTROL_SETTING: Setting = {
  id: "showRolesAndGroups",
  name: "showRolesAndGroups",
  category: SettingCategories.GENERAL,
  controlType: SettingTypes.CHECKBOX,
  label: "权限数据控制",
  text: "应用中可访问角色和用户组数据",
};

export const config: AdminConfigType = {
  icon: "settings-2-line",
  type: SettingCategories.GENERAL,
  controlType: SettingTypes.GROUP,
  categoryType: SettingCategories.GENERAL,
  title: "通用",
  canSave: true,
  settings: [
    ...(adminConfig.settings || []),
    APPSMITH_PROGRAMMATIC_ACCESS_CONTROL_SETTING,
  ],
} as AdminConfigType;
