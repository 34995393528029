import type { MenuItemProps } from "design-system-old";
import Api from "api/Api";

export const addItemsInContextMenu = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  args: boolean[],
  history: any,
  workspaceId: string,
  moreActionItems: MenuItemProps[],
) => {
  return moreActionItems;
};

export const useHtmlPageTitle = () => {
  return "Pageplug";
};

export const isCEMode = () => {
  return true;
};

export const ThirdPartyLogin = (
  requestUrl: any,
  param?: any,
  config?: any,
): void => {
  Api.get(requestUrl, param, config).then(({ data }) => {
    const url = data.redirectUrl;
    const newWindow: any = window.open(url, "_self");
    newWindow.focus();
  });
};
