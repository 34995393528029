export * from "ce/constants/ReduxActionConstants";
import {
  ReduxActionTypes as ReduxActionTypesCE,
  ReduxActionErrorTypes as ReduxActionErrorTypesCE,
} from "ce/constants/ReduxActionConstants";

export const ReduxActionTypes = {
  ...ReduxActionTypesCE,
  UPDATE_CURRENT_TENANT_CONFIG: "UPDATE_CURRENT_TENANT_CONFIG",
  UPDATE_CURRENT_TENANT_CONFIG_SUCCESS: "UPDATE_CURRENT_TENANT_CONFIG_SUCCESS",
  UPDATE_TENANT_CONFIG: "UPDATE_TENANT_CONFIG",
  UPDATE_TENANT_CONFIG_SUCCESS: "UPDATE_TENANT_CONFIG_SUCCESS",
  FETCH_USERS_INIT: "FETCH_USERS_INIT",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USER_DETAIL_INIT: "FETCH_USERS_DETAIL_INIT",
  FETCH_USER_DETAIL_SUCCESS: "FETCH_USERS_DETAIL_SUCCESS",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  FETCH_ROLES_FOR_USER_ASSIGN_INIT: "FETCH_ROLES_FOR_USER_ASSIGN_INIT",
  FETCH_ROLES_FOR_USER_ASSIGN_SUCCESS: "FETCH_ROLES_FOR_USER_ASSIGN_SUCCESS",
  FETCH_USER_GROUPS_FOR_INVITE_INIT: "FETCH_USER_GROUPS_FOR_INVITE_INIT",
  FETCH_USER_GROUPS_FOR_INVITE_SUCCESS: "FETCH_USER_GROUPS_FOR_INVITE_SUCCESS",
  FETCH_USER_GROUPS_INIT: "FETCH_USER_GROUPS_INIT",
  FETCH_USER_GROUPS_SUCCESS: "FETCH_USER_GROUPS_SUCCESS",
  FETCH_USER_GROUP_DETAIL_INIT: "FETCH_USER_GROUP_DETAIL_INIT",
  FETCH_USER_GROUP_DETAIL_SUCCESS: "FETCH_USER_GROUP_DETAIL_SUCCESS",
  CREATE_USER_GROUP: "CREATE_USER_GROUP",
  CREATE_USER_GROUP_SUCCESS: "CREATE_USER_GROUP_SUCCESS",
  DELETE_USER_GROUP: "DELETE_USER_GROUP",
  DELETE_USER_GROUP_SUCCESS: "DELETE_USER_GROUP_SUCCESS",
  UPDATE_USER_GROUP: "UPDATE_USER_GROUP",
  UPDATE_USER_GROUP_SUCCESS: "UPDATE_USER_GROUP_SUCCESS",
  USER_GROUP_INVITE_USER: "USER_GROUP_INVITE_USER",
  USER_GROUP_INVITE_USER_SUCCESS: "USER_GROUP_INVITE_USER_SUCCESS",
  USER_GROUP_REMOVE_USER: "USER_GROUP_REMOVE_USER",
  USER_GROUP_REMOVE_USER_SUCCESS: "USER_GROUP_REMOVE_USER_SUCCESS",
  USER_UPDATE_GROUPS: "USER_UPDATE_GROUPS",
  USER_UPDATE_GROUPS_SUCCESS: "USER_UPDATE_GROUPS_SUCCESS",
  ROLES_ASSOCIATE: "ROLES_ASSOCIATE",
  ROLES_ASSOCIATE_SUCCESS: "ROLES_ASSOCIATE_SUCCESS",
  FETCH_ROLES_INIT: "FETCH_ROLES_INIT",
  FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
  FETCH_ROLE_INIT: "FETCH_ROLE_INIT",
  FETCH_ROLE_SUCCESS: "FETCH_ROLE_SUCCESS",
  CREATE_ROLE_INIT: "CREATE_ROLE_INIT",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  DELETE_ROLE_INIT: "DELETE_ROLE_INIT",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  UPDATE_ROLE_INIT: "UPDATE_ROLE_INIT",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  FETCH_ROLE_CONFIG_INIT: "FETCH_ROLE_CONFIG_INIT",
  FETCH_ROLE_CONFIG_SUCCESS: "FETCH_ROLE_CONFIG_SUCCESS",
  UPDATE_ROLE_CONFIG_INIT: "UPDATE_ROLE_CONFIG_INIT",
  UPDATE_ROLE_CONFIG_SUCCESS: "UPDATE_ROLE_CONFIG_SUCCESS",
};

export const ReduxActionErrorTypes = {
  ...ReduxActionErrorTypesCE,
  UPDATE_CURRENT_TENANT_CONFIG_ERROR: "UPDATE_CURRENT_TENANT_CONFIG_ERROR",
  FETCH_CURRENT_TENANT_CONFIG_ERROR: "FETCH_CURRENT_TENANT_CONFIG_ERROR",
  UPDATE_TENANT_CONFIG_ERROR: "UPDATE_TENANT_CONFIG_ERROR",
  FETCH_USERS_ERROR: "FETCH_USERS_ERROR",
  FETCH_USER_DETAIL_ERROR: "FETCH_USERS_DETAIL_ERROR",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",
  FETCH_ROLES_FOR_USER_ASSIGN_ERROR: "FETCH_ROLES_FOR_USER_ASSIGN_ERROR",
  FETCH_USER_GROUPS_FOR_INVITE_ERROR: "FETCH_USER_GROUPS_FOR_INVITE_ERROR",
  FETCH_USER_GROUPS_ERROR: "FETCH_USER_GROUPS_ERROR",
  FETCH_USER_GROUP_DETAIL_ERROR: "FETCH_USER_GROUP_DETAIL_ERROR",
  CREATE_USER_GROUP_ERROR: "CREATE_USER_GROUP_ERROR",
  DELETE_USER_GROUP_ERROR: "DELETE_USER_GROUP_ERROR",
  UPDATE_USER_GROUP_ERROR: "UPDATE_USER_GROUP_ERROR",
  USER_GROUP_INVITE_USER_ERROR: "USER_GROUP_INVITE_USER_ERROR",
  USER_GROUP_REMOVE_USER_ERROR: "USER_GROUP_REMOVE_USER_ERROR",
  USER_UPDATE_GROUPS_ERROR: "USER_UPDATE_GROUPS_ERROR",
  ROLES_ASSOCIATE_ERROR: "ROLES_ASSOCIATE_ERROR",
  FETCH_ROLES_ERROR: "FETCH_ROLES_ERROR",
  FETCH_ROLE_ERROR: "FETCH_ROLE_ERROR",
  CREATE_ROLE_ERROR: "CREATE_ROLE_ERROR",
  DELETE_ROLE_ERROR: "DELETE_ROLE_ERROR",
  UPDATE_ROLE_ERROR: "UPDATE_ROLE_ERROR",
  FETCH_ROLE_CONFIG_ERROR: "FETCH_ROLE_CONFIG_ERROR",
  UPDATE_ROLE_CONFIG_ERROR: "UPDATE_ROLE_CONFIG_ERROR",
};
