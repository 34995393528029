import React, { useEffect } from "react";
import { connect } from "react-redux";
import { APPLICATIONS_URL } from "constants/routes";
import { Button } from "design-system";

import { flushErrorsAndRedirect } from "actions/errorActions";
import {
  BACK_TO_HOMEPAGE,
  createMessage,
  PAGE_NOT_FOUND,
  PAGE_NOT_FOUND_TITLE,
} from "@appsmith/constants/messages";
import AnalyticsUtil from "utils/AnalyticsUtil";
import styled from "styled-components";
import { getAppsmithConfigs } from "@appsmith/configs";
const { inCloudOS } = getAppsmithConfigs();
import Page from "./Page";

type Props = {
  flushErrorsAndRedirect: any;
};

const Text404 = styled.div`
  text-align: center;
  line-height: 200px;
  font-size: 100px;
  font-family: fantasy;
  filter: drop-shadow(2px 4px #ff0000) drop-shadow(2px 4px #00ff00)
    drop-shadow(-2px -4px #0000ff);
`;

function PageNotFound(props: Props) {
  const { flushErrorsAndRedirect } = props;

  useEffect(() => {
    AnalyticsUtil.logEvent("PAGE_NOT_FOUND");
  }, []);

  if (inCloudOS) {
    return <Text404>404</Text404>;
  }

  return (
    <Page
      cta={
        <Button
          className="mt-4 button-position"
          endIcon="right-arrow"
          kind="primary"
          onClick={() => flushErrorsAndRedirect(APPLICATIONS_URL)}
          size="md"
        >
          {createMessage(BACK_TO_HOMEPAGE)}
        </Button>
      }
      description="Either this page doesn't exist, or you don't have access to this page"
      errorCode={createMessage(PAGE_NOT_FOUND_TITLE)}
      title={createMessage(PAGE_NOT_FOUND)}
    />
  );
}

export default connect(null, {
  flushErrorsAndRedirect,
})(PageNotFound);
