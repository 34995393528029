export default [
  {
    sectionName: "",
    id: 1,
    children: [
      {
        label: "页面加载完成后立即运行 API",
        configProperty: "executeOnLoad",
        controlType: "SWITCH",
        subtitle: "是否在页面加载完成后执行查询",
      },
      {
        label: "运行API前请求用户确认",
        configProperty: "confirmBeforeExecute",
        controlType: "SWITCH",
        subtitle: "在每次请求数据时向用户确认",
      },
      {
        label: "API 超时时间 (毫秒)",
        subtitle: "容忍 API 多长时间不返回",
        configProperty: "actionConfiguration.timeoutInMillisecond",
        controlType: "INPUT_TEXT",
        dataType: "NUMBER",
      },
    ],
  },
];
