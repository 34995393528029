/* eslint-disable prettier/prettier */
import React from "react";
import ReactDOM from "react-dom";
import PageHeader from "pages/common/PageHeader";
import { Route, Switch } from "react-router";
import {
  VIEWER_PATH,
  BASE_URL,
  BUILDER_PATH,
  SETUP,
  SIGNUP_SUCCESS_URL,
  USER_AUTH_URL,
  BUILDER_PATH_DEPRECATED,
  VIEWER_PATH_DEPRECATED,
  ADMIN_SETTINGS_CATEGORY_PATH,
  VIEWER_CUSTOM_PATH,
  BUILDER_CUSTOM_PATH,
} from "constants/routes";
import Navigation from "pages/AppViewer/Navigation";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import AppEditorHeader from "pages/Editor/EditorHeader";
import { getAppsmithConfigs } from "@appsmith/configs";
import Setup from "pages/setup/index";
const { inCloudOS } = getAppsmithConfigs();

type Props = RouteComponentProps;

const headerRoot = document.getElementById("header-root");

class AppHeader extends React.Component<Props, any> {
  private container = document.createElement("div");

  componentDidMount() {
    headerRoot?.appendChild(this.container);
  }
  componentWillUnmount() {
    headerRoot?.removeChild(this.container);
  }
  get header() {
    if (inCloudOS) {
      return (
        <Switch>
          <Route component={AppEditorHeader} path={BUILDER_PATH_DEPRECATED} />
          <Route component={AppEditorHeader} path={BUILDER_PATH} />
          <Route component={AppEditorHeader} path={BUILDER_CUSTOM_PATH} />
          <Route component={Navigation} path={VIEWER_PATH_DEPRECATED} />
          <Route component={Navigation} path={VIEWER_PATH} />
          <Route component={Navigation} path={VIEWER_CUSTOM_PATH} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route component={PageHeader} path={ADMIN_SETTINGS_CATEGORY_PATH} />
        <Route component={undefined} path={USER_AUTH_URL} />
        <Route path={SETUP} />
        <Route path={SIGNUP_SUCCESS_URL} />
        <Route component={AppEditorHeader} path={BUILDER_PATH_DEPRECATED} />
        <Route component={Navigation} path={VIEWER_PATH_DEPRECATED} />
        <Route component={AppEditorHeader} path={BUILDER_PATH} />
        <Route component={AppEditorHeader} path={BUILDER_CUSTOM_PATH} />
        <Route component={Navigation} path={VIEWER_PATH} />
        <Route component={Navigation} path={VIEWER_CUSTOM_PATH} />
        <Route component={PageHeader} path={BASE_URL} />
      </Switch>
    );
  }
  render() {
    return ReactDOM.createPortal(this.header, this.container);
  }
}

export default withRouter(AppHeader);
