import React from "react";
import { Tag } from "design-system";

function BetaCard() {
  return (
    <div className="py-0.5 px-1 text-xs font-semibold text-gray-700 uppercase border border-gray-700">
      测试版
    </div>
  );
}

export default BetaCard;
